<template>
  <div class="login-wrap">
    <div class="ms-login">
      <div class="ms-title">商机云后台管理系统</div>
      <el-form
        :model="param"
        :rules="rules"
        ref="login"
        label-width="0px"
        class="ms-content"
      >
        <el-form-item prop="username">
          <el-input
            v-model="param.username"
            @input="getUserName"
            placeholder="username"
          >
            <template #prepend>
              <el-button icon="el-icon-user"></el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            type="password"
            placeholder="password"
            v-model="param.password"
            @keyup.enter="submitForm()"
            @input="getPwd"
          >
            <template #prepend>
              <el-button icon="el-icon-lock"></el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="identity" class="identity">
          <el-input
            v-model="identity"
            placeholder="验证码"
            style="margin-right: 10px"
            @input="getUserCode"
          >
          </el-input>
          <div @click="handleRefreshCode()">
            <Identify :identifyCode="identifyCode"></Identify>
          </div>
        </el-form-item>
        <div class="login-btn">
          <el-button
            type="primary"
            @click="submitForm()"
            :disabled="identityBol"
            >登录</el-button
          >
        </div>
        <p class="login-tips">提示 : 商机云后台管理系统</p>
      </el-form>
    </div>
  </div>
</template>

<script>
import { appRouter } from "@/main.js";
import Identify from "./Identity.vue";

import axios from "axios";
export default {
  components: { Identify },
  data() {
    return {
      lastIp: null,
      identityBol: true,
      identity: "",
      identifyCode: "", // 验证码初始值
      identifyCodes: "1234567890", // 验证码的随机取值范围
      id: "",
      param: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  created() {
    this.$store.commit("clearTags");
    this.$nextTick(() => {
      this.getIp();
      window.onload = function () {
        // 控制浏览器缩放
        document.body.style.zoom = "100%";
      };
    });
  },
  mounted() {
    this.$nextTick(() => {
      this.toLogin();
      // window.location.href = '/dashboard'

      this.handleRefreshCode();
    });
  },
  methods: {
    directLogin(params) {
      // console.log("params", params);
      axios
        // .post("http://106.14.0.130:9998/vo/login/login", params)
        // .post("http://syshb.cddmt.cn/api/login/login", params)
        .post("api/login/login", params)
        .then((res) => {
          // console.log("登录参数", res)
          // this.username = res.data.username;
          // this.password = res.data.password;
          if (res.data.statusCode == "00000") {
            this.$message.success("登录成功");
            localStorage.setItem("ms_username", params.username);
            localStorage.setItem("id", res.data.data.user.id);
            localStorage.setItem("money", res.data.data.user.money);
            localStorage.setItem("realname", res.data.data.user.realname);

            // console.log(res.data.data.user.realname)
            // 储存token到application 下的 localStorage
            localStorage.setItem("TOKEN", res.data.data.token);
            //储存Permission到application 下的 localStorage
            localStorage.setItem(
              "Permission",
              JSON.stringify(res.data.data.Permission)
            );

            let url = `http://syshb.cddmt.cn/audit`;
            // let url = `http://localhost:80/audit`;
            // window.open( url, "_self");
            window.location.href = url;

            // window.location.href = '/dashboard'
            // appRouter.push("/audit").catch(err => {
            //    // console.log('路由跳转出错：', err);
            // });
            // window.location.href = 'http://192.168.0.10/dashboard'
            //  // console.log(window.location);
          } else {
            // let url = `http://syshb.cddmt.cn/login`;
            // window.open( url, "_self");
            // window.location.href = url;
            history.replaceState(
              null,
              null,
              window.location.href.split("?")[0]
            );

            this.$message.error(res.data.message);
            // return false;
          }
        });
    },

    toLogin() {
      // 获取 URL 中的参数
      const query = window.location.search.substring(1);
      // 将参数字符串解析为对象
      const paramsR = Object.fromEntries(new URLSearchParams(query));

      if (paramsR.salesman) {
        // 保存saas用户名称,用于企业申请
        localStorage.setItem("salesman", paramsR.salesman);
      }

      if (paramsR.encryptionInfo) {
        let encryptionArr = paramsR.encryptionInfo.split("/");

        // 获取 参数
        // saas登录pass
        let params = {};
        if (encryptionArr[1] == 132242) {
          // 星链云跳转
          params = {
            username: "xinglianyun888",
            password: "xly88888",
            ipAddress: null,
          };
          this.directLogin(params);
        } else if (encryptionArr[1] == 20002) {
          // 慧聚云跳转
          params = {
            username: "sichuanhuitong",
            password: "scht123456",
            ipAddress: null,
          };
          this.directLogin(params);
        }
      }

      // 公众号登录pass
      if (paramsR.tempUuid) {
        localStorage.setItem("tempUuid", paramsR.tempUuid);

        let params = {
          tempUserId: paramsR.tempUuid,
        };
        // console.log("params", params);
        // 127.0.0.1:9001/login/login/weixin-uuid
        axios.post("api/login/login/weixin-uuid", params).then((res) => {
          this.username = res.data.username;
          this.password = res.data.password;
          if (res.data.statusCode == "00000") {
            this.$message.success("登录成功");
            localStorage.setItem("ms_username", "xinglianyun888");
            localStorage.setItem("id", res.data.data.user.id);
            localStorage.setItem("money", res.data.data.user.money);
            localStorage.setItem("realname", res.data.data.user.realname);

            //  // console.log(res.data.data.user.realname)
            //储存token到application 下的 localStorage
            localStorage.setItem("TOKEN", res.data.data.token);
            //储存Permission到application 下的 localStorage
            localStorage.setItem(
              "Permission",
              JSON.stringify(res.data.data.Permission)
            );

            let url = `http://syshb.cddmt.cn/audit`;
            // let url = `http://192.168.0.36:80/audit`;

            // window.open( url, "_self");
            window.location.href = url;
          } else {
            this.$message.error(res.data.message);
            // return false;
          }
        });
      }
    },
    getIp() {
      let _this = this;
      var data = { key: "UUKBZ-LA7LR-72RW6-WSFZS-JXDTJ-TZBZ2" }; //ip缺省时会自动获取请求端的公网IP,
      var url = "https://apis.map.qq.com/ws/location/v1/ip";
      data.output = "jsonp";
      $.ajax({
        type: "get",
        dataType: "jsonp",
        data: data,
        jsonp: "callback",
        url: url,
        success: function (json) {
          //  // console.log("  _json ", json.result.ip);
          // array.push(
          //   json.result.ad_info.province +
          //     json.result.ad_info.city +
          //     json.result.ad_info.district
          // ); //省市区
          // array.push(json.result.location.lat); //经度
          // array.push(json.result.location.lng); //纬度
          //  // console.log("array", array);
          // console.log("json", json);
          sessionStorage.setItem("ip", json.result.ip);
          _this.lastIp = sessionStorage.getItem("ip");
          // console.log("  _this.lastIp ", _this.lastIp);
          if (_this.lastIp) {
            _this.$message.success("IP地址获取成功");
          } else {
            _this.$message.error("IP地址获取失败");
          }
        },
        error: function (err) {
          //业务处理
          _this.$message.error("IP地址获取失败");
        },
      });
    },
    getUserCode() {
      if (
        this.param.username != "" &&
        this.param.password != "" &&
        this.identity == this.identifyCode
      ) {
        this.identityBol = false;
      } else {
        this.identityBol = true;
      }
    },
    // 账户输入
    getUserName() {
      if (
        this.param.username != "" &&
        this.param.password != "" &&
        this.identity == this.identifyCode
      ) {
        this.identityBol = false;
      } else {
        this.identityBol = true;
      }
    },
    // 密码输入
    getPwd() {
      if (
        this.param.username != "" &&
        this.param.password != "" &&
        this.identity == this.identifyCode
      ) {
        this.identityBol = false;
      } else {
        this.identityBol = true;
      }
    },
    // 刷新验证码
    handleRefreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    // 生成一个随机整数  randomNum(0, 10) 0 到 10 的随机整数， 包含 0 和 10
    randomNum(min, max) {
      max = max + 1;
      return Math.floor(Math.random() * (max - min) + min);
    },
    // 随机生成验证码字符串
    makeCode(data, len) {
      for (let i = 0; i < len; i++) {
        this.identifyCode += data[this.randomNum(0, data.length - 1)];
      }
      // console.log("这是验证码", this.identifyCode);
    },

    submitForm(saasParams) {
      localStorage.removeItem("salesman");
      localStorage.removeItem("tempUuid");

      let params;
      if (saasParams) {
        params = saasParams;
      } else {
        params = {
          username: this.param.username,
          password: this.param.password,
          ipAddress: this.lastIp,
        };
      }

      this.param.password = "";
      this.identity = "";
      axios
        // .post("http://106.14.0.130:9998/vo/login/login", params)
        // .post("http://syshb.cddmt.cn/api/login/login", params)
        .post("api/login/login", params)
        .then((res) => {
          // console.log("登录参数", res)
          this.username = res.data.username;
          this.password = res.data.password;
          if (res.data.statusCode == "00000") {
            this.$message.success("登录成功");
            localStorage.setItem("ms_username", this.param.username);
            localStorage.setItem("id", res.data.data.user.id);
            localStorage.setItem("money", res.data.data.user.money);
            localStorage.setItem("realname", res.data.data.user.realname);

            //  // console.log(res.data.data.user.realname)
            //储存token到application 下的 localStorage
            localStorage.setItem("TOKEN", res.data.data.token);
            //储存Permission到application 下的 localStorage
            localStorage.setItem(
              "Permission",
              JSON.stringify(res.data.data.Permission)
            );
            this.$router.push("/");
          } else {
            this.$message.error(res.data.message);
            // return false;
          }
        });
    },
  },
};
</script>

<style scoped lang="css">
@media screen and (max-width: 1026px) {
    .login-wrap>.ms-login{
     right: 50%;
     transform: translateX(50%);
    }
}


div /deep/ .el-form-item__content {
  display: flex;
}

.login-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(../assets/img/bg16.jpg);
  background-size: 100% 100%;
}

.ms-title {
  width: 100%;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  color: #333;
  font-weight: bold;
  border-bottom: 1px solid #333;
}

.ms-login {
  position: absolute;
  right: 20%;
  top: 50%;
  width: 350px;
  margin: -190px 0 0 -175px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.ms-content {
  padding: 30px 30px;
}

.login-btn {
  text-align: center;
}

.login-btn button {
  width: 100%;
  height: 36px;
  margin-bottom: 10px;
}

.login-tips {
  font-size: 12px;
  line-height: 30px;
  color: #333;
}
</style>